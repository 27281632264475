import React from "react"
import Layout from "../components/Layout"
import "../css/projects/web-project/web_proj1.css"
import { Link } from "gatsby"
import { FaCode, FaLink } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const data_proj1 = () => {
  return (
    <Layout>
      <SEO
        title="Restotante Con Fusion"
        description="Full Fledged Awesome Website Development (Web-Dev) project"
      />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/data/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="data-img-1">
          <div className="py-1-heading">
            <h1>Emergency-911 Calls</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Data-Capstone-Projects/tree/master/Emergency%20-%20911%20Calls"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>
        
        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It:</h3>
            <p>
              We will be analyzing some 911 call data from Kaggle.
              <br/>
              <br /> Continue with data exploration like info and head of data etc.
              
              <br />
              Creating new features as per requirement.<br/>
              (Eg: extracting hour, month from a timestamp column)
              <br />
                
              <br /> Used diffrent graph visualisation as per required<br/>
              Like:- Countplot, Lmplot, Leatmap, Clustermap etc.
              <br />
            </p>

            <h3>Skills Behind It:</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Pandas</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Numpy</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Matplotlib</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Seaborn</span>
              </button> 

            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default data_proj1
